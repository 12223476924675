import { laptopMax, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.theme.background};
  height: 54px;
  display: flex;
  flex: 1;
  border-radius: 6px;
  min-width: 0;
  align-items: center;
  padding: 0 6px 0 15px;
`;

export const Input = styled.input`
  flex: 1;
  background: transparent;
  border: none;
  font-size: 16px;
  overflow: hidden;
  color: ${(props) => props.theme.textDark};
  height: 100%;
  line-height: 22px;
  min-width: 0;

  &::placeholder {
    color: ${(props) => props.theme.backgroundLight};
  }

  &::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }

  &::selection {
    color: ${(props) => props.theme.white};
    background: ${(props) => props.theme.primary};
  }
`;

export const SearchInputIcon = styled.button`
  padding: 10px 8px;
  height: 100%;

  svg {
    color: ${(props) => props.theme.textDark};
  }

  &:hover svg {
    color: ${(props) => props.theme.primary};
  }

  @media (${mobileXXLargeMax}) {
    display: none;
  }
`;

export const SearchMobileIcon = styled(SearchInputIcon)`
  display: none;
  border-left: 1px solid ${({ theme }) => theme.keyline};
  padding-left: 14px;

  @media (${laptopMax}) {
    display: block;
  }
`;

export const LoadingBox = styled.div`
  margin: 5px 10px 0 0;
`;

export const SuggestionList = styled.ul<{ offset?: number; leftOffset?: number; isHomePage?: boolean }>`
  position: absolute;
  top: ${(props) => props.offset || 60}px;
  left: ${(props) => props.leftOffset || 0}px;
  background-color: rgba(255, 255, 255, 0.95);
  width: 100%;
  padding: 0;
  margin: 0;
  max-height: calc(100vh - 200px);
  border-radius: 6px;
  z-index: 90;
  overflow-x: hidden;
  overflow-y: auto;

  ${(props) =>
    props.isHomePage &&
    `
    margin: 0 0 0 -6px;
    
    @media (${mobileXXLargeMax}) {
      margin: 0;
      left: 0;
    }
  `}
`;

export const Header = styled.h3`
  background-color: ${(props) => props.theme.backgroundLighter};
  padding: 12px 25px;
  font-size: 16px;
`;

export const Item = styled.li<{ focused?: boolean }>`
  position: relative;
  width: 100%;
  padding: 12px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  cursor: pointer;

  p {
    flex: 1;
  }

  button {
    position: absolute;
    width: 50px;
    height: 100%;
    right: 0;
    top: 0;
  }

  &:hover {
    background-color: ${(props) => props.theme.backgroundLightest};
  }

  ${(props) =>
    props.focused &&
    `
    background-color: ${props.theme.backgroundLightest};
  `}
`;

export const ItemImage = styled.img`
  margin-right: 10px;
  max-height: 45px;
`;
